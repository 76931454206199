import { MainNavigation, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { session } from "../../../session/Session";
import EditContactPerson from "./EditContactPerson";
import EditDetails from "./EditDetails";
import EditTitleImage from "./EditTitleImage";
import ObjectTransfer from "./ObjectTransfer";
import TrainingAdministration from "./TrainingAdministration";
import { isTrainingCompany } from "../../../utils/isTrainingCompany";

enum SubViewKey {
    ContactPerson = "tab__contact-person",
    Details = "tab__details",
    TitleImage = "tab__title-image",
    ObjectTransfer = "tab__object-transfer",
    TrainingAdministration = "tab__training-administration",
}

export interface EditModalProps {
    onError?: (error: Error | undefined) => void;
    selectedBuildingId: number;
}

const Edit: FunctionComponent<EditModalProps> = (props: EditModalProps) => {
    const [subView, setSubView] = useState<SubViewKey>(SubViewKey.Details);
    const isTrainingCompanyX = isTrainingCompany(session.currentUser?.company.id);
    return (
        <MainNavigation
            activeId={subView}
            onActiveChange={(id) => setSubView(id as SubViewKey)}
            items={[
                {
                    id: SubViewKey.Details,
                    text: "Objektdetails",
                    view: (
                        <S.View>
                            <EditDetails selectedBuildingId={props.selectedBuildingId} />
                        </S.View>
                    ),
                },
                {
                    id: SubViewKey.ContactPerson,
                    text: "Ansprechpartner",
                    view: (
                        <S.View>
                            <EditContactPerson selectedBuildingId={props.selectedBuildingId} />
                        </S.View>
                    ),
                },
                {
                    id: SubViewKey.TitleImage,
                    text: "Titelbild",
                    view: (
                        <S.View>
                            <EditTitleImage selectedBuildingId={props.selectedBuildingId} onError={props.onError} />
                        </S.View>
                    ),
                },
                {
                    id: SubViewKey.ObjectTransfer,
                    text: "Objekt transferieren",
                    hidden: session.hasCompanyAdminRights !== true && session.hasAbsAdminRights !== true,
                    view: (
                        <S.View>
                            <ObjectTransfer selectedBuildingId={props.selectedBuildingId} />
                        </S.View>
                    ),
                },
                {
                    id: SubViewKey.TrainingAdministration,
                    text: "Schulungsverwaltung",
                    hidden: !isTrainingCompanyX,
                    view: (
                        <S.View>
                            <TrainingAdministration selectedBuildingId={props.selectedBuildingId} />
                        </S.View>
                    ),
                },
            ]}
            small
        />
    );
};

export default observer(Edit);

//#region styles
const S = {
    View: styled.div`
        margin-top: ${unitize(60)};
    `,
};
//#endregion styles
